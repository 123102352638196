@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

body {
  overflow-x: hidden;
}

/* :root {
  --text-colour-main: rgb(23, 45, 73);
  --bg-colour-main: #d9d9d9;
  --blue-colour-main: rgb(23, 45, 73);
  --font-size: 20px;
} */

/* .home-section {
  width: 100vw;
  background-color: #d9d9d9;
} */

/* h1,
h2,
h3,
h4,
h5 {
  font-family: "Oswald", sans-serif;
} */

/* p {
  font-family: "Raleway", sans-serif;
} */

/* navbar */

/* * {
  background-color: var(--bg-colour-main);
  color: var(--text-colour-main);
  font-size: var(--font-size);
}

.home-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home-top {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.home-top-p {
  width: 706px;
}

.home-top-img {
  width: 420px;
  height: 306px;
  border-radius: 50px;
  margin-right: 50px;
}

.home-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
}

.home-bottom-p {
  width: 706px;
}

.home-bottom-img {
  width: 350px;
  height: 350px;
  border-radius: 100%;
  margin-left: 50px;
}

.services-section {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.service-item {
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  width: 640px;
  height: 400px;
  margin: 75px;
}

.services-section {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.service-item:last-child {
  grid-column: 1 / -1;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  height: 400px;
  width: 100%;
  background-color: none;
  border: 1px solid black;
}

.service-item-body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.services-item-description {
  text-align: left;
  width: 265px;
  margin-right: 26.5px;
}

.services-image {
  border-radius: 50px;
  width: 350px;
  height: 306px;
}

.service-title {
  text-align: center;
  width: 100%;
}

.contact-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 1px solid red;
  height: 565px;
}

.contact-icon {
  width: 24px;
  height: 24px; 
  margin-right: 10px; 
}

.contact-left {
  width: 650px;
  height: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
} */
