/** @format */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.home-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 60px 0;
}

.home {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.home-image,
.home-text {
  flex: 1;
  max-width: 40%;
}

.home-text p {
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  color: #000000;
  line-height: 1.5;
}

.home-image img {
  width: 420px;
  height: auto;
  border-radius: 50px;
}

.home-image {
  display: flex;
  justify-content: center;
}

/* Responsive design  */

@media (max-width: 1200px) {
  .home {
    flex-direction: column;
    align-items: center;
  }

  .home-image,
  .home-text {
    max-width: 100%;
    text-align: center;
  }

  .home-image img {
    max-width: 350px;
  }
}

@media (max-width: 768px) {
  .home-text p {
    font-size: 18px;
  }

  .home-image img {
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .home-text p {
    font-size: 16px;
    padding: 0px 25px;
  }

  .home-image img {
    max-width: 250px;
  }
}
