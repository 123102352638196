.contact-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 90vw;
  /* padding-right: 150px; */
  /* height: 565px; */
}

.contact-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.contact-left {
  width: 650px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
}

@media (max-width: 768px) {
  .contact-body {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    width: 95%;
    margin-top: 60px;
    margin-bottom: 60px;
    padding-right: 150px;
    padding-left: 10px;
  }
}
