@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.container-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #172d49;
  width: 100%;
  font-family: "Raleway", sans-serif;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 20px 0;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 60px;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-size: 18px;
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

.hamburger-icon {
  color: white;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #172d49;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .nav-links.open {
    margin: auto;
    margin-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    display: flex;
  }

  .nav-links ul {
    flex-direction: column;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .hamburger {
    display: block;
  }
}
