@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.contact-container {
  background-color: #172d49;
  color: white;
  padding: 40px;
  border-radius: 10px;
  max-width: 500px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  font-family: "Raleway", sans-serif;
}

.contact-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: white;
}

span {
  color: white;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  margin-bottom: 40px;
}

.contact-item img {
  font-size: 24px;
  margin-right: 50px;
}

.address-text {
  text-align: left;
}

.icon {
  width: 35px;
  height: 35px;
  fill: white;
}
