/**
 * .App {
 *   background-color: #d9d9d9;
 *   width: 100%;
 * }
 *
 * @format
 */

/* *{
  background-color: #d9d9d9;
} */
* {
  margin: 0;
  padding: 0;
}

.app {
  background-color: #d9d9d9;
  width: 100%;
}

@media (max-width: 768px) {
  .app {
    width: 100%;
    overflow-x: hidden;
  }
}
