@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

/* body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: #172d49;
  font-family: "Oswald", sans-serif;
} */

.contact-form {
  background-color: #172d49;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 600px;
}

label {
  display: block;
  margin-bottom: 8px;
  color: white;
  background-color: #172d49;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
  color: #172d49;
}

input::placeholder,
textarea::placeholder {
  color: #172d49;
}

button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button.success {
  background-color: green;
}

.submit-button.failure {
  background-color: red;
}

@media (max-width: 768px) {
  .contact-form {
    background-color: #172d49;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%; /* Adjusted for mobile */
    box-sizing: border-box;
  }

  label {
    display: block;
    margin-bottom: 8px;
    color: white;
    background-color: #172d49;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: white;
    color: #172d49;
  }

  input::placeholder,
  textarea::placeholder {
    color: #172d49;
  }

  button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
  }

  .submit-button.success {
    background-color: green;
  }

  .submit-button.failure {
    background-color: red;
  }
}
