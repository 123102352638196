@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.services-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.service-item {
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  width: 640px;
  height: 400px;
  margin: 75px;
}

.service-item:last-of-type {
  grid-column: 1 / -1;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  height: 400px;
  width: 100%;
  /* background-color: red; */
}

.services-image:last-of-type {
  width: 50%;
}

.last-service-container {
  grid-column: 1 / -1;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.another-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.last-service-item {
  box-sizing: border-box;
  padding: 10px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  text-align: left;
  background-color: none;
}

/*  */
.last-service-title {
  color: #172d49;
  text-align: center;
  width: 100%;
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  margin-bottom: 15px;
}

.last-service-item-body {
  gap: 5px;
}

.last-services-item-description > li {
  color: #172d49;
  text-align: left;
  /* width: 265px; */
  /* margin-right: 26.5px; */
  font-family: "Oswald", sans-serif;
  font-size: 20px;
}

.last-services-item-description > li:last-child {
  color: #172d49;
  text-align: left;
  /* width: 265px; */
  /* margin-right: 26.5px; */
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  margin-bottom: 25px;
}

.additional_info {
  color: #172d49;
  text-wrap: wrap;
  padding-right: 100px;
}

/*  */

.service-title {
  color: #172d49;
  text-align: center;
  width: 100%;
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  margin-bottom: 15px;
}

.service-item-body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.last-service-container-heading {
  color: #172d49;
  font-size: 28px;
  font-family: "Oswald", sans-serif;
  margin-bottom: 25px;
}

.services-item-description > li {
  color: #172d49;
  text-align: left;
  width: 265px;
  margin-right: 26.5px;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
}

.services-image {
  border-radius: 50px;
  width: 350px;
  height: 306px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .services-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .service-item {
    width: 100%;
    height: auto;

    /* margin: 0 20px; */
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }

  .service-title {
    margin-top: 15px;
  }

  .service-item-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* .last-service-item {
    text-align: left;
  } */

  .services-image {
    width: 45%;
    height: auto;
  }

  .additional_info {
    padding-right: 20px;
  }

  .services-item-description > li {
    width: 100%;
    /* padding-left: 15px; */
    text-align: center;
    list-style: none;
  }

  .last-services-item-description > li {
    width: 100%;
    margin-right: 0;
    text-align: center;
  }
  .last-service-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 25px;
  }
  .another-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .another-container > last-service-title {
    text-align: center;
  }
  .last-services-item-description > li {
    text-align: left;
  }
  .last-services-item-description > li {
    list-style: none;
  }
}
